<template>
    <div>
        <div class="workPlan A4Container flexCloumnSingleCenter">
            <div class="title flexCenter">实习管理典型案例</div>
            <div class="form" style="padding: 0!important;">
                <el-row class="el-row" style="line-height: 40px;border-bottom:1px solid #000;">
                    <el-col :span="8" class="flexStart" style="border-right:1px solid #000;padding-left: 10px;">
                        班级：{{ formData.class_name || '' }}
                    </el-col>
                    <el-col :span="8" class="flexStart" style="border-right:1px solid #000;padding-left: 10px;">
                        教师：{{ formData.create_name || '' }}
                    </el-col>
                    <el-col :span="8" class="flexStart" style="padding-left: 10px;display: flex!important;">
                        填写日期：{{ formData.report_time || '' }}
                    </el-col>
                </el-row>
                <div class="textIndent2" style="padding: 10px;line-height: unset;">
                    {{ formData.case_content || '' }}
                </div>
                <div class="posDate">{{ formData.create_date || '' }}</div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'contact',
    data() {
        return {
            dataover: false,
            userid: '',
            formData: [{}],
        }
    },
    props: {
        dataInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        type: {
            type: Number,
            default: 1
        }
    },
    mounted() {
        let _this = this
        setTimeout(function () {
            let info = JSON.parse(JSON.stringify(_this.dataInfo))
            console.log(58, info)
            _this.formData = info
            _this.$nextTick(() => {
                // console.log(65,_this.formData)
                // _this.resizeA()
            })
        }, 500)
    },
    methods: {
        resizeA() {
            let id1 = 'divCntnrA'
            let id2 = 'resizeDivA'

            let divHeight = document.getElementById(id1).offsetHeight
            let textHeight = document.getElementById(id2).offsetHeight
            // console.log('a', divHeight, textHeight)
            let i = 1
            while (textHeight > divHeight && i < 7) {
                document.getElementById(id2).style.fontSize = `${18 - i}px`
                textHeight = document.getElementById(id2).offsetHeight
                i++

            }
        },
    }
}
</script>
