<template>
    <div>
        <div class="pdfok" id="pdfok" v-if="pdfok"></div>
        <div v-if="dataover222" id="printMe" style=" width: 21cm; margin: 0 auto;">
            <div v-if="show_dev1" v-for="(item, index) in dataList" :key="index + 'a'">
                <InnerPage_sxglcase :idx="index" :dataInfo="item"></InnerPage_sxglcase>
            </div>
        </div>
        <!--    <el-button v-print="'#printMe'" type="primary" style="position: fixed;top: 60px;right: 10px;z-index: 999;">-->
        <!--      打印-->
        <!--    </el-button>-->

    </div>
</template>

<script>
import InnerPage_sxglcase from './com_all/InnerPage_sxglcase'

export default {
    name: 'export',
    components: {
        InnerPage_sxglcase,
    },
    data() {
        return {
            pdfok: false,
            show_dev: true,
            show_dev1: true,
            dataover222: true,
            printObj: {
                id: 'printMe',
                popTitle: '',
                extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
            },
            userid: '',

            stuInfo: {},
            yuebao: [{}],
            zhoubao: [{}],
            chinaCitys: [],
            // 填入导出的pdf文件名和html元素
            pdfTitle: '试卷1',
            pdfSelector: '#printMe',
            dataList: [{}]
        }
    },
    mounted() {
        let id = this.getParams('id')
        let pmid = this.getParams('pmid')
        // let id = 'fd05786013204a96ae7586c9cd77a32a'
        console.log(1111111, id, pmid)

        this.getDataList(pmid)

    },
    methods: {
        //过滤特殊字符, 校验所有输入域是否含有特殊符号
        stripscript(s) {
            var mdash = "&mdash;";
            var nbsp = "&nbsp;";
            var quot = "&quot;";
            var ldquo = "&ldquo;";
            var rdquo = "&rdquo;";
            var amp = "&amp;";
            var lt = "&lt;";
            var gt = "&gt;";
            var hellip = "&hellip;";

            var rs = "";
            rs = s.replace(new RegExp(mdash, "g"), "-");
            rs = rs.replace(new RegExp(nbsp, "g"), "");
            rs = rs.replace(new RegExp(quot, "g"), '"');
            rs = rs.replace(new RegExp(ldquo, "g"), "“");
            rs = rs.replace(new RegExp(rdquo, "g"), "”");
            rs = rs.replace(new RegExp(amp, "g"), "&");
            rs = rs.replace(new RegExp(lt, "g"), "<");
            rs = rs.replace(new RegExp(gt, "g"), ">");
            rs = rs.replace(new RegExp(hellip, "g"), ">");
            return rs;
        },



        getDataList(pmid) {
            let _this = this
            this.$http.post('/api/init_sx_sxglcase_teacherall_pdf', {
                pmid: parseInt(pmid),
                class_name: '518'
            }).then(res => {
                if (res.data) {
                    let rData = res.data
                    res.data.map(a => {
                        if (a.report_time) {
                            a.report_time = a.report_time.substr(0, 10)
                        }
                        if (a.create_date) {
                            a.create_date = a.create_date.substr(0, 10)
                        }
                        a.case_content = a.case_content ? this.stripscript(a.case_content) : ''
                    })
                    this.dataList = res.data
                }

                this.$nextTick(() => {
                    setTimeout(() => { _this.pdfok = true }, 500)
                })

            })

        },

        getParams(name) {
            let n_url = window.location.href
            n_url = n_url.replace('/#', '')
            if (n_url.indexOf('?') > 0) {
                n_url = n_url.split('?')[1]
            }
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
            var r = n_url.match(reg)
            if (r != null) return unescape(r[2])
            return null
        },
        //分割数组 arr：原数组  num：分割后每个数组里的数据条数
        util_spliceArrByN(arr, num) {
            var _arr = []
            while (arr.length) {
                _arr.push(arr.splice(0, num))
            }
            return _arr
        },
        //数组按某属性分组
        groupBy(array, f) {
            let groups = {}
            array.forEach(function (o) {
                let group = JSON.stringify(f(o))
                groups[group] = groups[group] || []
                groups[group].push(o)
            })
            return Object.keys(groups).map(function (group) {
                return groups[group]
            })
        }
    }
}
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>
